import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { appendData } from "../../../redux/action";
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import "./navigation.css";

const Navigation = (props) => {
  const pages = useRef();
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const userInfos = useSelector((state) => state.userInfos);

  const searchPages = () => {
    if (pages.current && pages.current.value !== "") {
      let newPageList = Object.values(props.rolesOld).filter((item) => item.title.toLowerCase().indexOf(pages.current.value.toLowerCase()) !== -1);
      props.setRoles(newPageList);
    } else {
      props.setRoles(props.rolesOld);
    }
  }

  const keyPressFunc = (e) => {
    if (e.charCode === 13) {
      searchPages();
    }
  }

  const openDropdown = (index) => {
    let showClassExist = false;
    for (let item of document.getElementById("menu" + index).classList) {
      if (item == "show") {
        showClassExist = true;
      }
    }
    if (showClassExist) {
      document.getElementById("menu" + index).classList.remove("show");
    } else {
      document.getElementById("menu" + index).classList.add("show");
    }
  }

  const logout = () => {
    props.appendData({ token: null, userInfos: null });
    navigate("/login");
  }

  useEffect(() => {
    let pages = Object.keys(props.roles);
    if (pages && pages.length > 0) {
      for (let i = 0; i < pages.length; i++) {
        if (userInfos && userInfos.user_role.pages.length > 0) {
          Object.values(props.roles)[i].access = userInfos.user_role.pages[i].checked
        }
      }
    }
    setRoles(props.roles);
  }, [props.roles]);

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a href="/dashboard" className="brand-link">
        <div className="row">
          <div className="col-lg-12">
            <img src="/logo.png" alt="NYON Logo" className="brandLogo" />
          </div>
          <div className="col-lg-12 text-center">
            <span className="brand-text font-weight-light">NYON Admin Panel</span>
          </div>
        </div>
      </a>

      <div className="sidebar">
        <div className="row">
          <div className="col-lg-12 userLogoPart">
            <a href="#" className="d-block">{userInfos.username}</a>
          </div>
          {userInfos.user_role && userInfos.user_role.name ?
            <div className="col-lg-12 userTitle">
              <a href="#" className="d-block"> ({userInfos.user_role.name})</a>
            </div> : null}
        </div>

        <nav className="navbar navbar-inverse" id="sidebar-wrapper" role="navigation">
          <ul className="nav sidebar-nav">
       
            {Object.keys(roles) && Object.keys(roles).length > 0 ?
              Object.keys(roles).map((item, index) =>
                <li key={index}>
                  {Object.values(roles)[index].access ? <>
                    <a href={Object.values(roles)[index].link} onClick={() => openDropdown(index)}>
                      <i className={Object.values(roles)[index].icon}></i>
                      <span>   {Object.values(roles)[index].title}  </span>
                      {Object.values(roles)[index].subCategories && Object.values(roles)[index].subCategories.length > 0 ?
                        <i className="fa fa-arrow-circle-down" style={{ marginLeft: 50 }}></i> : null}
                    </a>
                    {Object.values(roles)[index].subCategories && Object.values(roles)[index].subCategories.length > 0 ?
                      <ul className="dropdown-menu animated fadeInLeft" id={"menu" + index} role="menu" >
                        {Object.values(roles)[index].subCategories.map((el, i) =>
                          <li key={i} className="subCategory">
                            <a href={el.link}>
                              <i className={el.icon}></i>
                              <span>   {el.title}  </span>
                            </a>
                          </li>
                        )}
                      </ul> : null}
                  </> : null}
                </li>) : null}
            <li>
              <a href="#" onClick={() => logout()}>
                <i className="fa fa-close"></i>
                <span>   Çıkış  </span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}

const mapDispatchToProps = {
  appendData
}

const mapStateToProps = state => ({
  token: state.token,
  userInfos: state.userInfos
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);